import bgFind from './assets/images/imgdb.jpg'
import bgForge from './assets/images/imgdbseated.jpg'
import bgUnleash from './assets/images/blackplates.jpg'
import bgHomeworkout from './assets/images/workoutmat.jpg'
import bgMass from './assets/images/imgbb.jpg'
import bgCoaching from './assets/images/imgteam.jpg'
import bgBundle from './assets/images/plates.jpg'

const sales =
  {
    bundles: [
      {
        name: '6 MONTH TRANSFORMATION',
        description: [
          'Find The Beast',
          'Forge The Beast',
          'Unleash The Beast'
        ],
        fulldescription: 'This bundle is for beginners who are looking for a structured plan to get started in the gym, and looking to progress throughout the weeks and months to come. Getting the three programs at once provides you with structure and accountability for a 6-month period.',
        slug: 'beginner-program',
        price: '119.98',
        link: process.env.REACT_APP_API_COACHING1,
        img_url: bgBundle,
        slogan: "The Ultimate Package For Beginners"
      }
    ],
    programmes: [
      {
        name: 'Find The Beast',
        slug: 'find-the-beast',
        duration: '3-4 days/week',
        description: 'Gym equipment, My TOP 3 beginner tips, Demonstration videos',
        fulldescription: 'This program is ideal if you can train 3 to 4 days a week and/or if you are just starting out in the gym (or restart after a long break). For beginners this is the program you need. It will teach you all the important basics and set you up for long term sucess.',
        price: '49.99',
        // bundle_id: bundle1.id,
        link: process.env.REACT_APP_API_FINDTHEBEAST,
        img_url: bgFind
      },
      {
        name: 'Forge The Beast',
        slug: 'forge-the-beast',
        duration: '4-5 days/week',
        description: 'Gym equipment, My TOP 3 advanced tips, Demonstration videos',
        fulldescription: 'This program is ideal if you can train 4 to 5 days a week and/or you already have a little bit of experience in the gym. You are still a beginner but the gym is not a foreign place for you. You are familiar with the basic exercises and movements and you know how it feels to guide these weights around.',
        price: '49.99',
        position: 'mid',
        // bundle_id: bundle1.id,
        link: process.env.REACT_APP_API_FORGETHEBEAST,
        img_url: bgForge
      },
      {
        name: 'Unleash The Beast',
        slug: 'unleash-the-beast',
        duration: '5-6 days/week',
        description: 'Gym equipment, My TOP 3 expert tips, Demonstration videos',
        fulldescription: 'This program is ideal if you can train 5 to 6 days a week and/or you are quite comfortable in the gym. You know your body, you know what you are supposed to feel when doing an exercise and you can handle some weight.',
        price: '49.99',
        // bundle_id: bundle1.id,
        link: process.env.REACT_APP_API_UNLEASHTHEBEAST,
        img_url: bgUnleash
      },
      {
        name: 'Homeworkout Pump',
        slug: 'bodyweight',
        duration: '4-5 days/week',
        description: 'Bodyweight and bands (no pull up bar needed), Demonstration videos',
        fulldescription: 'This program is ideal if you can train 4 to 5 days a week and/or you are training at home without gym equipment (only your bodyweight, bands and normal things you have in your house like a chair or a couch).',
        price: '39.99',
        link: process.env.REACT_APP_API_HOME,
        img_url: bgHomeworkout
      },
      // {
      //   name: 'Booty Special',
      //   slug: 'booty-special',
      //   duration: '1 month',
      //   description: 'Videos for demonstration',
      //   price: '49.99',
      //   img_url:
      // },
      {
        name: 'Mass Builder',
        slug: 'mass-builder',
        duration: '5-6 days/week',
        description: 'Gym equipment, Demonstration videos',
        fulldescription: "This program is ideal if you can train 5 to 6 days a week and/or you are a bit more advanced. You've been training consistently for a while, you've already made good progress and you're looking for that next step.",
        price: '49.99',
        link: process.env.REACT_APP_API_MASS,
        img_url: bgMass
      }
    ],
    coachings: [
      {
        name: '1-1 Coaching',
        slug: '1-1-coaching',
        duration: '1 month',
        descriptions: [
          'Anamnesis',
          'Personal Training Plan',
          'Eat what you want (no detailed nutrition plan)',
          'Weekly check-in',
          'Contact me anytime'
        ],
        price: '120',
        img_url: bgCoaching
      }
    ]
  }


export default sales
