import React from 'react';
import { useForm } from '@formspree/react';
function ContactForm() {
  const [state, handleSubmit] = useForm("mwkjnady");
  if (state.succeeded) {
    alert("Message successfully sent.")
      return (
        <section className="bg-programme-blue">
          <div className="mx-5 lg:mx-auto py-10 lg:w-1/2 text-lg">
            <h2 className="mb-5 lg:mx-40 text-xl font-bold text-center text-white">Contact Me</h2>
            <p className="mb-5 font-light text-white text-center">Message successfully sent. NevskiFit will be in touch with you soon.</p>
          </div>
        </section>
      );
  }
  return (
    <section className="bg-programme-blue">
      <div className="mx-5 lg:mx-auto py-10 lg:w-2/3">
          <h2 className="mb-5 lg:mx-40 text-xl font-bold text-center text-white">Contact Me</h2>
          {/* <p className="mb-5 font-light lg:text-lg text-center text-white">Do you have further questions about the 1-1 coaching? Is there anything else you want to ask me? Feel free to leave me a message below.</p> */}
          <form onSubmit={handleSubmit} className="space-y-5">
              <div>
                  <label htmlFor="name" className="block mb-2 font-small text-white">Name</label>
                  <input type="text" name="name" className="shadow-sm bg-gray-50 border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" placeholder="Enter your name" required />
              </div>
              <div>
                  <label htmlFor="email" className="block mb-2 font-small text-white">Your email</label>
                  <input type="email" name="email" className="shadow-sm bg-gray-50 border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5" placeholder="name@email.com" required />
              </div>
              <div className="sm:col-span-2">
                  <label htmlFor="message" className="block mb-2 font-small text-white">Your message</label>
                  <textarea name="message" rows="4" className="block p-2.5 w-full bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 " placeholder="Leave your enquiry..."></textarea>
              </div>
              <button type="submit" className="hover:font-bold py-3 px-5  text-center rounded-lg bg-yellow sm:w-fit hover:bg-primary-500 focus:ring-4 focus:outline-none focus:ring-primary-300">Send message</button>
          </form>
      </div>
    </section>
  );
}

export default ContactForm
