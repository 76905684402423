import React from 'react'
// import Tooltip from "./Tooltip";

function ProgramExplanationBox(props) {
    return (
        <div className='lg:w-1/4 p-5 m-1 justify-center border border-yellow text-black rounded-lg'>
            {/*<Tooltip text={props.description} children={props.title}></Tooltip>*/}
            <p className=''><b>{props.title}</b></p>
            <p className=''>{props.description}</p>
        </div>
    )
}
export default ProgramExplanationBox

