import React from 'react'
import Box from './ProgramBulletBox';

function ProgramExplanation() {
    return (
        <section className="bg-coaching-blue bg-opacity-5">
            <div className='p-10 justify-center flex flex-col lg:flex-row'>
                <Box title="Train from home" description="I know you don't have time or no motivation to go to a commercial gym so this program allows you to exclusively train at home while being accompanied by a personal trainer."></Box>
                <Box title="Schedule the training sessions yourself" description="I know sometimes something unexpected comes up and you have to be flexible and spontaneous. Stop having to plan your training sessions in advance and cancel them last minute. This program allows you to set your training sessions freely so that it fits your daily schedule."></Box>
                <Box title="No equipment needed" description="I know you might not have any training equipment and therefore you don't know what to do. Or maybe you do have some but still don't know what to do with it. This program allows you to train with whatever equipment you already have or not."></Box>
                <Box title="No experience needed" description="I know you probably have no or very little experience when it comes to training so you might think you won't be able to do the exercises or that it will be complicated. This program is made for beginners so everything is adjusted to your level to reach your goal step by step."></Box>
                <Box title="Stop counting calories" description="I know you hate the idea of having to weigh your food and count the calories of everything that you eat. Something that you may have been told to do in the past but got tired of very quickly. This program allows you to improve your nutrition while eating the things that you like without wasting your time counting calories."></Box> 
            </div>
        </section>
    )
}

export default ProgramExplanation


