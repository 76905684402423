import React from 'react'

function Intro(props) {
  const text = <>
      <div className='lg:w-1/2 flex-col m-8 lg:px-20 lg:m-0 flex justify-center'>
        <h2 className='text-center mb-10 text-xl font-bold tracking-wide'>{props.title}</h2>
        <p className='text-justify'>{props.text}<a href={props.pathname} className='font-bold underline'>{props.linkInline}</a></p>
        <p className='text-justify'>{props.extratext}<a href={props.pathname} className='font-bold underline'>{props.link}</a></p>
      </div>
    </>
  const img =  <>
    <img className="lg:w-1/2 object-cover" src={props.img} alt={props.description}/>
  </>

  return (
    <div>
      <div className='flex flex-col lg:flex-row lg:h-[460px]'>
        {props.location === 'left' ?
        <>{text}{img}</> :
        <div className='flex flex-col-reverse lg:flex-row'>
          {img}{text}
        </div>
        }
      </div>
    </div>
  )
}

export default Intro
